import { staticSuperTable } from '~utils/mapping-filters'

const mapStaffMember = (staffMember) => {
  const {
    id,
    fullName,
    bio,
    image,
    role,
    links,
    craft
  } = staffMember

  const metadata = staticSuperTable(staffMember.metadata)

  return {
    id,
    fullName,
    bio: bio ? bio.content : '',
    image: image[0],
    role,
    links,
    craft: craft[0],
    metadata
  }
}

export const mapCraft = (craft) => {
  return {
    ...craft,
    staffMember: mapStaffMember(craft.staffMember)
  }
}
