import React from 'react'
import { graphql } from 'gatsby'

import PageLayer from '~components/PageLayer'
import SEO from '~components/SEO'
import { MastheadStaffMember } from '~components/Masthead'
import TextBlock from '~components/TextBlock'
import theme from '~styles/theme'

import { mapCraft } from './mappings'

const StaffMembersTemplate = ({ data }) => {
  const { staffMember } = mapCraft(data.craft)

  return (
    <PageLayer>
      <SEO
        title={staffMember.fullName}
        metadata={staffMember.metadata}
      />
      <MastheadStaffMember
        heading={staffMember.fullName}
        image={staffMember.image}
        backgroundColor={theme.colors.grey}
      />
      <TextBlock
        heading={staffMember.role}
        body={staffMember.bio}
        fontSize={0}
      />
    </PageLayer>
  )
}

export const query = graphql`
query ($id: Int) {
  craft {
    staffMember: entry(id: [$id], section: staffMembers) {
      ...staffMemberFields
      ...on Craft_StaffMembers {
        metadata {
          ...metadataFields
        }
      }
    }
  }
}
`

export default StaffMembersTemplate